const RouterPaths = {
  CONTACT: "/contact",
  ABOUT: "/about",
  SERVICES: "/services",
  PROJECTS: "/projects",
  CAREERS: "/careers",
  LANDING: "/"
};

export default RouterPaths;
